var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{staticClass:"multi-col-validation"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('strong',[_vm._v(_vm._s(_vm.$t('lbl.method'))+":")]),_vm._v(" "),_c('code',[_vm._v("POST")]),_c('br'),_c('br'),_c('code',[_vm._v(" "+_vm._s(_vm.url)+" ")]),_c('br'),_c('strong',[_vm._v("JSON - "+_vm._s(_vm.$t('lbl.request')))]),_c('json-viewer',{attrs:{"value":{'api_key':_vm.api_key,
                     'secret_token':_vm.secret_token,
                     'page':parseInt(_vm.page),
                     'per_page':parseInt(_vm.per_page),
                     'recogida':_vm.recogida,
                     'dateRecogida':_vm.dateRecogida,
                     'entrega':_vm.entrega,
                     'dateEntrega':_vm.dateEntrega,
                     'entregaDiffRecogida':_vm.entregaDiffRecogida,
                     'transmision_id':_vm.transmision_id,
                     'rentadora_id':_vm.rentadora_id,},"sort":true,"boxed":true}})],1)],1)],1),(_vm.jsonData != null)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('strong',[_vm._v("JSON - "+_vm._s(_vm.$t('lbl.response')))]),_c('json-viewer',{attrs:{"value":_vm.jsonData,"sort":true,"boxed":true}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-row',[_c('v-col',{staticClass:"pt-5 pb-5",attrs:{"cols":"12","md":"12"}},[_c('strong',[_vm._v(_vm._s(_vm.$t('lbl.params')))])])],1),_c('v-row',{staticClass:"py-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-0",attrs:{"cols":"12","md":!_vm.entregaDiffRecogida ? '6' : '3'}},[_c('div',{staticClass:"d-flex justify-start align-center"},[(_vm.recogida === null || _vm.recogida === '')?_c('v-autocomplete',{staticClass:"pr-2",style:(!_vm.entregaDiffRecogida ? '' : 'border-radius: 5px 0px 0px 5px'),attrs:{"items":_vm.itemsRecogida,"search-input":_vm.searchRecogida,"label":!_vm.entregaDiffRecogida ? _vm.$t('lbl.recogidaEntrega') : _vm.$t('lbl.recogida'),"outlined":"","dense":"","item-text":"name","item-value":"id","single-line":"","clearable":"","return-object":""},on:{"update:searchInput":function($event){_vm.searchRecogida=$event},"update:search-input":function($event){_vm.searchRecogida=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.noResultRecogida ? _vm.$t('lbl.noResultSearchFlight') : _vm.$t('lbl.recogida'))+" ")])],1)]},proxy:true},{key:"item",fn:function(ref){
                     var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)]}},{key:"selection",fn:function(ref){
                     var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}],null,false,1195937550),model:{value:(_vm.recogida),callback:function ($$v) {_vm.recogida=$$v},expression:"recogida"}}):_c('v-text-field',{ref:"autocomplete",style:(!_vm.entregaDiffRecogida ? '' : 'border-radius: 5px 0px 0px 5px'),attrs:{"label":!_vm.entregaDiffRecogida ? _vm.$t('lbl.recogidaEntrega') : _vm.$t('lbl.recogida'),"outlined":"","dense":"","single-line":"","clearable":""},on:{"click:clear":_vm.clearRecogida,"input":_vm.inputRecogida},model:{value:(_vm.recogida.name),callback:function ($$v) {_vm.$set(_vm.recogida, "name", $$v)},expression:"recogida.name"}})],1)]),(_vm.entregaDiffRecogida)?_c('v-col',{staticClass:"pl-5",attrs:{"cols":"12","md":"3"}},[(_vm.entrega === null || _vm.entrega === '')?_c('v-autocomplete',{staticClass:"pr-2",staticStyle:{"border-radius":"0px 5px 5px 0px"},attrs:{"items":_vm.itemsEntrega,"search-input":_vm.searchEntrega,"label":_vm.$t('lbl.entrega'),"outlined":"","dense":"","item-text":"name","item-value":"id","return-object":"","single-line":"","clearable":""},on:{"update:searchInput":function($event){_vm.searchEntrega=$event},"update:search-input":function($event){_vm.searchEntrega=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.noResultEntrega ? _vm.$t('lbl.noResultSearchFlight') : _vm.$t('lbl.entrega'))+" ")])],1)]},proxy:true},{key:"item",fn:function(ref){
                     var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)]}},{key:"selection",fn:function(ref){
                     var item = ref.item;
return [_c('span',[_vm._v(_vm._s(((item.name.slice(0, 20)) + "...")))])]}}],null,false,698289464),model:{value:(_vm.entrega),callback:function ($$v) {_vm.entrega=$$v},expression:"entrega"}}):_c('v-text-field',{ref:"autocomplete",staticStyle:{"border-radius":"5px 0px 0px 5px"},attrs:{"label":_vm.$t('lbl.entrega'),"outlined":"","dense":"","single-line":"","clearable":""},on:{"click:clear":_vm.clearEntrega,"input":_vm.inputEntrega},model:{value:(_vm.entrega.name),callback:function ($$v) {_vm.$set(_vm.entrega, "name", $$v)},expression:"entrega.name"}})],1):_vm._e(),_c('v-col',{staticClass:"pl-5",attrs:{"cols":"12","md":"3"}},[_c('vc-date-picker',{staticClass:"d-flex justify-center;z-index: 1 !important",attrs:{"outlined":"","dense":"","hide-details":"","min-date":new Date(),"mode":"dateTime","model-config":_vm.modelConfig},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                     var inputValue = ref.inputValue;
                     var inputEvents = ref.inputEvents;
return [_c('v-text-field',_vm._g({staticStyle:{"border-radius":"5px 0px 0px 5px"},attrs:{"label":_vm.$t('lbl.dateRecogida'),"outlined":"","dense":"","value":inputValue}},inputEvents))]}}]),model:{value:(_vm.dateRecogida),callback:function ($$v) {_vm.dateRecogida=$$v},expression:"dateRecogida"}})],1),_c('v-col',{staticClass:"pl-5",attrs:{"cols":"12","md":"3"}},[_c('vc-date-picker',{attrs:{"outlined":"","dense":"","hide-details":"","min-date":_vm.$moment(_vm.dateRecogida)
                .add(1, 'days')
                .toDate(),"mode":"dateTime","model-config":_vm.modelConfig},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var inputValue = ref.inputValue;
                var inputEvents = ref.inputEvents;
return [_c('v-text-field',_vm._g({ref:"dateTo",staticStyle:{"border-radius":"0px 5px 5px 0px"},attrs:{"disabled":!_vm.dateRecogida,"label":_vm.$t('lbl.dateEntrega'),"outlined":"","dense":"","value":inputValue}},inputEvents))]}}]),model:{value:(_vm.dateEntrega),callback:function ($$v) {_vm.dateEntrega=$$v},expression:"dateEntrega"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-switch',{staticClass:"pb-0 mt-0",attrs:{"label":_vm.$t('lbl.entregaDiffRecogida')},on:{"change":_vm.reseatDiffRecogida},model:{value:(_vm.entregaDiffRecogida),callback:function ($$v) {_vm.entregaDiffRecogida=$$v},expression:"entregaDiffRecogida"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{attrs:{"items":_vm.transmisiones,"item-text":"name","item-value":"id","label":_vm.$t('menu.trasnmision'),"outlined":"","dense":"","hide-details":"","clearable":""},model:{value:(_vm.transmision_id),callback:function ($$v) {_vm.transmision_id=$$v},expression:"transmision_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{staticClass:"pl-2",attrs:{"items":_vm.itemsRentadora,"search-input":_vm.searchRentadora,"label":_vm.$t('menu.rentadora'),"outlined":"","dense":"","item-text":"name","item-value":"id","return-object":"","single-line":"","clearable":""},on:{"update:searchInput":function($event){_vm.searchRentadora=$event},"update:search-input":function($event){_vm.searchRentadora=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.noResultRentadora ? _vm.$t('lbl.noResultSearchFlight') : _vm.$t('menu.rentadora'))+" ")])],1)]},proxy:true},{key:"item",fn:function(ref){
                var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)]}},{key:"selection",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}}]),model:{value:(_vm.rentadora_id),callback:function ($$v) {_vm.rentadora_id=$$v},expression:"rentadora_id"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{staticClass:"mb-3",attrs:{"outlined":"","label":_vm.$t('lbl.page'),"hide-details":"","dense":"","type":"number","min":"1"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{staticClass:"mb-3",attrs:{"outlined":"","label":_vm.$t('lbl.perPage'),"hide-details":"","dense":"","type":"number","min":"1"},model:{value:(_vm.per_page),callback:function ($$v) {_vm.per_page=$$v},expression:"per_page"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-btn',{attrs:{"color":"primary","block":"","dark":"","large":"","loading":_vm.loading},on:{"click":function($event){return _vm.save()}}},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.send')))]),_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiUpdate))])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }