<template>
  <v-row>
    <!-- kitchen sink -->
    <v-col cols="12">
      <v-card>
        <v-card-title>{{ `${$t('menu.cars')} / ${$t('lbl.disponibilidad')}` }}</v-card-title>
        <v-card-text>
          <Availability />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import Availability from './form/Availability.vue'

export default {
  components: {
    Availability,
  },
}
</script>
