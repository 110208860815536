<template>
  <v-form class="multi-col-validation">
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <strong>{{ $t('lbl.method') }}:</strong> <code>POST</code>
            <br>
            <br>
            <code>
              {{ url }}
            </code>
            <br>
            <strong>JSON - {{ $t('lbl.request') }}</strong>
            <json-viewer
              :value="{'api_key':api_key,
                       'secret_token':secret_token,
                       'page':parseInt(page),
                       'per_page':parseInt(per_page),
                       'recogida':recogida,
                       'dateRecogida':dateRecogida,
                       'entrega':entrega,
                       'dateEntrega':dateEntrega,
                       'entregaDiffRecogida':entregaDiffRecogida,
                       'transmision_id':transmision_id,
                       'rentadora_id':rentadora_id,}"
              :sort="true"
              :boxed="true"
            ></json-viewer>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-if="jsonData != null"
        cols="12"
        md="6"
      >
        <strong>JSON - {{ $t('lbl.response') }}</strong>
        <json-viewer
          :value="jsonData"
          :sort="true"
          :boxed="true"
        ></json-viewer>
      </v-col>

      <v-col
        cols="12"
        md="12"
      >
        <v-row>
          <v-col
            cols="12"
            md="12"
            class="pt-5 pb-5"
          >
            <strong>{{ $t('lbl.params') }}</strong>
          </v-col>
        </v-row>

        <v-row
          no-gutters
          class="py-0"
        >
          <v-col
            cols="12"
            :md="!entregaDiffRecogida ? '6' : '3'"
            class="pr-0"
          >
            <div class="d-flex justify-start align-center">
              <v-autocomplete
                v-if="recogida === null || recogida === ''"
                v-model="recogida"
                :items="itemsRecogida"
                :search-input.sync="searchRecogida"
                :label="!entregaDiffRecogida ? $t('lbl.recogidaEntrega') : $t('lbl.recogida')"
                outlined
                dense
                :style="!entregaDiffRecogida ? '' : 'border-radius: 5px 0px 0px 5px'"
                item-text="name"
                item-value="id"
                single-line
                clearable
                return-object
                class="pr-2"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ noResultRecogida ? $t('lbl.noResultSearchFlight') : $t('lbl.recogida') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
                <template v-slot:selection="{ item }">
                  {{ item.name }}
                </template>
              </v-autocomplete>
              <v-text-field
                v-else
                ref="autocomplete"
                v-model="recogida.name"
                :label="!entregaDiffRecogida ? $t('lbl.recogidaEntrega') : $t('lbl.recogida')"
                outlined
                dense
                :style="!entregaDiffRecogida ? '' : 'border-radius: 5px 0px 0px 5px'"
                single-line
                clearable
                @click:clear="clearRecogida"
                @input="inputRecogida"
              >
              </v-text-field>
            </div>
          </v-col>
          <v-col
            v-if="entregaDiffRecogida"
            cols="12"
            md="3"
            class="pl-5"
          >
            <v-autocomplete
              v-if="entrega === null || entrega === ''"
              v-model="entrega"
              :items="itemsEntrega"
              :search-input.sync="searchEntrega"
              :label="$t('lbl.entrega')"
              outlined
              dense
              style="border-radius: 0px 5px 5px 0px"
              item-text="name"
              item-value="id"
              return-object
              single-line
              clearable
              class="pr-2"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    {{ noResultEntrega ? $t('lbl.noResultSearchFlight') : $t('lbl.entrega') }}
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:item="{ item }">
                <!--<v-icon left>
                {{ icons.mdiAirplaneTakeoff }}
              </v-icon>-->
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-slot:selection="{ item }">
                <span>{{ `${item.name.slice(0, 20)}...` }}</span>
              </template>
            </v-autocomplete>
            <v-text-field
              v-else
              ref="autocomplete"
              v-model="entrega.name"
              :label="$t('lbl.entrega')"
              outlined
              dense
              style="border-radius: 5px 0px 0px 5px"
              single-line
              clearable
              @click:clear="clearEntrega"
              @input="inputEntrega"
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="pl-5"
          >
            <vc-date-picker
              v-model="dateRecogida"
              outlined
              dense
              hide-details
              :min-date="new Date()"
              mode="dateTime"
              :model-config="modelConfig"
              class="d-flex justify-center;z-index: 1 !important"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <v-text-field
                  :label="$t('lbl.dateRecogida')"
                  outlined
                  dense
                  :value="inputValue"
                  style="border-radius: 5px 0px 0px 5px"
                  v-on="inputEvents"
                >
                </v-text-field>
              </template>
            </vc-date-picker>
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="pl-5"
          >
            <vc-date-picker
              v-model="dateEntrega"
              outlined
              dense
              hide-details
              :min-date="
                $moment(dateRecogida)
                  .add(1, 'days')
                  .toDate()
              "
              mode="dateTime"
              :model-config="modelConfig"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <v-text-field
                  ref="dateTo"
                  :disabled="!dateRecogida"
                  :label="$t('lbl.dateEntrega')"
                  outlined
                  dense
                  :value="inputValue"
                  style="border-radius: 0px 5px 5px 0px"
                  v-on="inputEvents"
                >
                </v-text-field>
              </template>
            </vc-date-picker>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <v-switch
              v-model="entregaDiffRecogida"
              :label="$t('lbl.entregaDiffRecogida')"
              class="pb-0 mt-0"
              @change="reseatDiffRecogida"
            ></v-switch>
          </v-col>

          <v-col
            cols="12"
            md="2"
          >
            <v-select
              v-model="transmision_id"
              :items="transmisiones"
              item-text="name"
              item-value="id"
              :label="$t('menu.trasnmision')"
              outlined
              dense
              hide-details
              clearable
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-autocomplete
              v-model="rentadora_id"
              :items="itemsRentadora"
              :search-input.sync="searchRentadora"
              :label="$t('menu.rentadora')"
              outlined
              dense
              item-text="name"
              item-value="id"
              return-object
              single-line
              clearable
              class="pl-2"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    {{ noResultRentadora ? $t('lbl.noResultSearchFlight') : $t('menu.rentadora') }}
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-slot:selection="{ item }">
                <!--<span>{{ `${item.name.slice(0, 20)}...` }}</span>-->
                <span>{{ item.name }}</span>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="2"
          >
            <v-text-field
              v-model="page"
              outlined
              :label="$t('lbl.page')"
              hide-details
              class="mb-3"
              dense
              type="number"
              min="1"
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-text-field
              v-model="per_page"
              outlined
              :label="$t('lbl.perPage')"
              hide-details
              class="mb-3"
              dense
              type="number"
              min="1"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="2"
          >
            <v-btn
              color="primary"
              block
              dark
              large
              :loading="loading"
              @click="save()"
            >
              <span>{{ $t('btn.send') }}</span>
              <v-icon>{{ icons.mdiUpdate }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import {
  mdiMagnify, mdiDeleteOutline, mdiSquareEditOutline, mdiPlus, mdiArrowLeft, mdiUpdate,
} from '@mdi/js'

// eslint-disable-next-line import/no-unresolved
// import LeafletExample from '@/views/leaflet/Example.vue'

export default {
  components: {

    // LeafletExample,
  },
  data() {
    return {
      isLoading: true,
      jsonFile: null,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
      },
      loading: false,
      jsonData: null,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD HH:mm',
      },
      url: `${process.env.VUE_APP_API_URL}car/availability`,
      api_key: sessionStorage.getItem('api_key'),
      secret_token: sessionStorage.getItem('secret_token'),
      page: 1,
      per_page: 10,

      entregaDiffRecogida: false,
      recogida: null,
      entrega: null,
      dateRecogida: null,
      dateEntrega: null,
      transmision_id: 0,
      rentadora_id: 0,
      itemsRecogida: [],
      noResultRecogida: false,
      searchRecogida: null,
      itemsEntrega: [],
      noResultEntrega: false,
      searchEntrega: null,
      itemsRentadora: [],
      noResultRentadora: false,
      searchRentadora: null,
      transmisiones: [],
    }
  },
  watch: {
    searchRecogida(val) {
      if (val.length > 2) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterRecogida(val.toLowerCase())
      } else {
        this.noResultRecogida = false
      }
    },
    searchEntrega(val) {
      if (val.length > 2) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterEntrega(val.toLowerCase())
      } else {
        this.noResultEntrega = false
      }
    },
    searchRentadora(val) {
      if (val.length > 1) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterRentadora(val.toLowerCase())
      } else {
        this.noResultRentadora = false
      }
    },
  },
  created() {
    this.getTransmisiones()
  },

  methods: {
    filterRecogida(v) {
      this.itemsRecogida = []
      // eslint-disable-next-line no-empty
      if (v !== '') {
        // OFICINAS
        this.axios
          .post(
            'oficina-renta-filter-contrate',
            { search: v },
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(res => {
            const result = res.data.data.data

            result.forEach(element => {
              // eslint-disable-next-line no-nested-ternary
              this.itemsRecogida.push({
                id: element.id,
                name: element.name,
                type: 'oficina',
              })
            })
          })

        // DESTINOS
        this.axios
          .post(
            'destinos-filter-contrate',
            { search: v },
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(res => {
            const result = res.data.data.data

            result.forEach(element => {
              // eslint-disable-next-line no-nested-ternary
              this.itemsRecogida.push({
                id: element.id,
                name: element.destino,
                type: 'destino',
              })
            })
          })

        setTimeout(() => {
          if (this.itemsRecogida.length === 0) {
            this.noResultRecogida = true
          }
        }, 500)
      }
    },
    filterEntrega(v) {
      this.itemsEntrega = []

      if (v !== '') {
        // OFICINAS
        this.axios
          .post(
            'oficina-renta-filter-contrate',
            { search: v },
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(res => {
            const result = res.data.data.data

            result.forEach(element => {
              // eslint-disable-next-line no-nested-ternary
              this.itemsEntrega.push({
                id: element.id,
                name: element.name,
                type: 'oficina',
              })
            })
          })

        // DESTINOS
        this.axios
          .post(
            'destinos-filter-contrate',
            { search: v },
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(res => {
            const result = res.data.data.data

            result.forEach(element => {
              // eslint-disable-next-line no-nested-ternary
              this.itemsEntrega.push({
                id: element.id,
                name: element.destino,
                type: 'destino',
              })
            })
          })

        setTimeout(() => {
          if (this.itemsEntrega.length === 0) {
            this.noResultEntrega = true
          }
        }, 500)
      }
    },
    filterRentadora(v) {
      this.itemsRentadora = []
      if (v !== '') {
        this.axios
          .post(
            'rentadoras-filter-contrate',
            { search: v },
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(res => {
            const result = res.data.data.data

            result.forEach(element => {
              // eslint-disable-next-line no-nested-ternary
              this.itemsRentadora.push({
                id: element.id,
                name: element.name,
              })
            })

            if (this.itemsRentadora.length === 0) {
              this.noResultRentadora = true
            }
          })
      }
    },
    getTransmisiones() {
      this.axios
        .post('nomenclators/transmition-cars', {
          api_key: this.api_key,
          secret_token: this.secret_token,
          page: 1,
          per_page: 1000,
        })
        .then(response => {
          this.transmisiones = response.data.data.items
        })
        .catch(error => console.log(error))
    },
    reseatDiffRecogida() {
      if (!this.entregaDiffRecogida) {
        this.entrega = null
        this.searchEntrega = null
        this.itemsEntrega = []
      }
    },
    clearRecogida() {
      this.recogida = null
    },
    inputRecogida() {
      if (
        this.recogida.name === null
        || this.recogida.name === ''
      ) {
        this.clearRecogida()
      }
    },
    clearEntrega() {
      this.entrega = null
    },
    inputEntrega() {
      if (
        this.entrega.name === null
        || this.entrega.name === ''
      ) {
        this.clearRecogida()
      }
    },

    setJson(e) {
      if (e) {
        this.readFile(e)
      } else {
        this.jsonData = null
      }
    },
    readFile(file) {
      const reader = new FileReader()
      reader.onload = e => {
        // eslint-disable-next-line no-unused-vars
        this.jsonData = JSON.parse(e.target.result)
      }
      reader.readAsText(file)
    },
    save() {
      if (this.recogida && this.dateRecogida && this.dateEntrega) {
        this.loading = true
        this.jsonData = null
        let proceder = true

        if (this.entregaDiffRecogida) {
          if (!this.entrega) {
            proceder = false
          }
        }

        if (proceder) {
          const json = {
            api_key: this.api_key,
            secret_token: this.secret_token,
            page: this.page,
            per_page: this.per_page,
            recogida: this.recogida,
            dateRecogida: this.dateRecogida,
            entrega: this.entrega,
            dateEntrega: this.dateEntrega,
            entregaDiffRecogida: this.entregaDiffRecogida,
            transmision_id: this.transmision_id,
            rentadora_id: this.rentadora_id,
          }

          this.axios
            .post('car/availability', json)
            .then(response => {
              if (!response.data.success) {
                if (response.data.status === 406) {
                  this.$toast.error(this.$t('msg.error.406'))
                }
              }
              this.jsonData = response.data
            })
            .catch(error => console.log(error))
          // eslint-disable-next-line no-return-assign
            .finally(() => this.reset())
        } else {
          this.loading = false
          this.$toast.error(this.$t('msg.datasRequiere'))
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    reset() {
      this.loading = false
    },
  },
}
</script>
